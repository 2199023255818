@import url('_colors.css'); /* universal color variables */
@import url('_login.css');
@import url('_footer.css');
@import url('_home.css');
@import url('_landing_legal.css');
@import url('_legend.css');
@import url('_pdf.css');
@import url('_purchasableProcedure.css');
@import url('_spacers.css');
@import url('_subscription.css');
@import url('_uploader.css');
@import url('_first_experience.css');
@import url('_dashboard.css');
@import url('_register.css');

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-Heavy.ttf") format("truetype");
    font-weight: 800 900;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-HeavyItalic.ttf") format("truetype");
    font-weight: 800 900;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-DemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-DemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-Regular.ttf") format("truetype");
    font-weight: 300 400;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-Italic.ttf") format("truetype");
    font-weight: 300 400;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-UltraLight.ttf") format("truetype");
    font-weight: 100 200;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNext-UltraLightItalic.ttf") format("truetype");
    font-weight: 100 200;
    font-style: italic;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    font-family: "Avenir Next", Avenir, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.site-colors {
    background-color: #2f4864; /* nav background */

    color: #faeb8f; /* plus icon */
    background-color: #faeb8f; /* bar */
    background-color: #e4d369; /* bar button */
    border-color: #e4d369; /* bar border */
    border-color: #cebb3f; /* bar button border */
    color: #aaacb0; /* nav text */
    color: #3b4249; /* text */

    background-color: #f7f8fb; /* tree background */

    border-color: #e1e5ea; /* field border */
    background-color: #ebedf1; /* field hierarchy left side */
    background-color: #e8dc72; /* field drag&drop placement */
    border-color: #e5e7e9; /* field dragging border */
    background-color: #f7f8fb; /* field dragging grabber */
    color: #b33d31; /* field delete */
    background-color: #f5f7fa; /* field selected background */

    /*background-color: #eeeff2; old page background */
    background-color: #ffffff; /* page background */
}

.covid-notice {
    background-color: transparent;
    margin: .5rem auto;
    border:0;
    border-bottom: 1px solid black;
    font-size: 14px;
}

.covid-notice:focus {
    outline: none;
}

.covid-notice-container {
    background-color: var(--dark-yellow);
    text-align: center;
    width:100%;
    margin: 0;
}

.custom-container {
    margin-left: 2%;
    margin-right: 2%;
}

nav.navbar, footer {
    background-color: var(--dodger-blue);
    padding-left: 0;
    padding-right: 0;
}

.navbar-nav > a.yellow-button:focus, .navbar-nav > a.btn-outline-secondary:focus,
.covid-notice-container button.covid-notice:focus {
    outline: 2px solid var(--darker-dodger-blue);
}

#hero-banner .justify-content-between .btn-primary.yellow-button:focus, #row6 .btn-primary.yellow-button:focus {
    outline: 2px solid #ffffff;
}

nav.navbar > div.custom-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

nav.navbar > div.custom-container > div#basic-navbar-nav {
    margin-left: auto;
}

@media screen and (max-width:992px) {
    .PublicLayout nav.navbar .navbar-nav:not(.mr-auto) {
        margin-top: .5em;
    }
    nav.navbar .navbar-nav .btn.mr-2 {
        margin-right: 0 !important;
        margin-bottom: .5em;
    }
    .navbar-collapse .divider-vertical {
        display: none;
    }
    .header-link-section a {
        display: block;
    }
    .header-link-section a,
    .navbar-nav .nav-link {
        padding: .75rem 1rem;
    }
    nav.navbar .header-link-section ~ .navbar-nav:not(.mr-auto) {
        margin-top: 0;
    }
}

#help-collapsible-menu,
.header-link-section {
    color: white;
    /*margin-right: 3rem;*/
}
.header-link-section .divider-vertical {
    margin: 0 !important;
}

#root, .PublicLayout, .PrivateLayout { /* help footer stick to the bottom of page */
    min-height: 100vh;
}

#root .PublicLayout, .PrivateLayout { /* help footer stick to the bottom of page */
    display: flex;
    flex-direction: column;
}

.PublicLayout footer ul li, .PublicLayout footer ul li a {
    color: var(--darker-dodger-blue)!important;
    font-size: 16px;
}

#root > div > div.container {
    padding-bottom: 40px;
}

.update-used-procedure form .divider-vertical,
.update-procedure form .divider-vertical,
.navbar .divider-vertical {
    height: 38px;
    margin: 0 25px;
    /*border-right: 1px solid #aaacb0;*/
    border-left: 1px solid #ffffff;
}
.navbar .divider-vertical.divider-vertical-small {
    height: 18px;
    margin: 10px 0.5rem;
}
.navbar-inverse .divider-vertical {
    border-right-color: #222222;
    border-left-color: #111111;
}
@media (max-width: 767px) {
    .navbar-collapse .nav > .divider-vertical {
        display: none;
    }
}

body {
    background-color: #ffffff;
    overflow-x: hidden;
}

.navbar .navbar-nav a.nav-link,.navbar .navbar-nav > a {
    color: #ffffff;
    border-color: #ffffff;
}

.navbar .navbar-nav a.btn:hover {
    background-color: var(--darker-dodger-blue);
}

.user-navigation .dropdown-menu {
    left: auto;
    right: 0;
}

.progress-bar {
    background-color: #dfe1e6;
}

.form-control {
    color: #3b4249;
}

.form-actions-wrapper {
    background-color: var(--lista-main-yellow);
    border-bottom: 1px solid var(--dark-yellow);

    height: 70px;
    width: 1050%; /* width of element (50%) + padding-left (500%) + padding-right (500%) */
    margin-left: -500%;
    padding-left: 500%;
    margin-right: -500%;
    padding-right: 500%;
    padding-top: 1rem;
    margin-top: -1rem; /* move closer to header */
    margin-bottom: 2rem; /* spacing from content below */
    float: left; /* allow submit button to move up */
}
.form-actions-wrapper .form-actions .btn {
    margin-right: 0.5rem;
}
/* Safari issue with top buttons not being visible */
.form-actions-wrapper + form > .pull-right > div > .form-group > input,
.form-actions-wrapper + form > .pull-right > .select-category-button > .toggle-button {
    opacity: 0.999;
}

.page-actions-wrapper {
    background-color: var(--lista-main-yellow);
    border-bottom: 1px solid var(--dark-yellow);

    height: 70px;
    width: 1100%; /* width of element (100%) + padding-left (500%) + padding-right (500%) */
    margin-left: -500%;
    padding-left: 500%;
    margin-right: -500%;
    padding-right: 500%;
    padding-top: 1rem;
    margin-top: -1rem; /* move closer to header */
    margin-bottom: 2rem; /* spacing from content below */
}
.page-actions-wrapper .page-actions {
    float: right;
}

.page-used-procedure-list .page-actions-wrapper .page-actions,
.page-procedure-list .page-actions-wrapper .page-actions {
    float: left;
}

.page-used-procedure-list .usedprocedure-list-table-wrapper, .procedure-list-table-wrapper , .user-table-list-wrapper {
    overflow-x: scroll;
    padding-bottom: 7rem; /* allow room for last row dropdown menu */
}

.procedure-list-table-wrapper table thead th a,
.procedure-list-table-wrapper table tbody td a.primary-action,
.usedprocedure-list-table-wrapper table thead th a,
.usedprocedure-list-table-wrapper table tbody tr a,
.page-tag-list table tbody a,
.user-table-list-wrapper table tbody a {
    text-decoration: underline;
}

.page-tag-list table tbody .dropdown a,
.usedprocedure-list-table-wrapper table tbody .dropdown a,
.user-table-list-wrapper table tbody .dropdown a
{
    text-decoration: none;
}

.procedure-list-table-wrapper table div.dropdown div , .usedprocedure-list-table-wrapper table div.dropdown div , .page-list table div.dropdown div  {
    left: -50px!important;
}

#procedure-list-tooltip {
    opacity: 0;
    transition-delay: 100ms;
    position: absolute;
    transform: translate(-30px, -30px);
    font-size: 11px;
    font-weight: normal;
    background-color: #EFEFEF;
    padding: 3px;
    border: 1px solid lightgrey;
}

#tooltip-parent > div > div > button {
    border: none;
    background-color: transparent;
    transform: translateY(-10px);
}

#tooltip-parent > div > div > button:focus {
    outline: none;
}

#tooltip-parent > div > div > button:focus ~ span#procedure-list-tooltip {
    opacity: 1;
    transition-delay: 100ms;
}

@media(max-width: 414px) {
    #procedure-list-tooltip {
        transform: translate(-80px, -4rem);
        min-width: 150px;
    }
}

@media(min-width: 415px){
    #tooltip-parent > div > div:hover span#procedure-list-tooltip { /* this way on info-icon-btn:focus tooltip doesnt appear delayed*/
        opacity: 1;
        transition-delay: 1500ms;
    }
}


.table thead th {
    background-color: var(--lista-main-yellow);
    border: 1px solid var(--dark-yellow);
    border-left: 0;
    border-right: 0;
}
.table thead th:first-child {
    border-left: 1px solid var(--dark-yellow);
}
.table thead th:last-child {
    border-right: 1px solid var(--dark-yellow);
}
/*
.table {
    border-collapse: separate;
}
.table thead th:first-child {
    border-radius: 5px 0 0 0;
}
.table thead th:last-child {
    border-radius: 0 5px 0 0;
}
*/

.table .tag-name {
    display: inline-block;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    background: var(--tag-color);
    color: white;
    padding: .2rem .4rem;
    border-radius: 4px;
}

.table-striped tbody .progress {
    background-color: darkgray;
}

.custom-btn-warning.disabled,
.custom-btn-warning:disabled,
.custom-btn-warning {
    background-color: #ffe68e;
    border-color: #ffe68e;
    color: #323a40;
}

button:hover:enabled , button.btn.custom-btn-warning:hover, a.btn-primary:hover {
    filter: contrast(80%) !important;
}

a.btn-primary:hover{
    background-color: var(--darker-dodger-blue);
}

.btn-info.disabled,
.btn-info:disabled,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary {
    background-color: var(--dodger-blue);
    border-color: var(--dodger-blue);
    color: #ffffff;
}

.form-actions-wrapper + form > .pull-right > .select-category-button > .toggle-button,
.form-actions-wrapper .form-actions .btn-danger, .edit-tag-wrapper .page-actions-wrapper .btn-yellow,.edit-user-wrapper .page-actions-wrapper .btn-yellow,
.form-actions-wrapper .form-actions .btn-yellow,
.form-actions-wrapper .form-actions .btn-primary {
    background-color: var(--lista-marketing-yellow);
    border-color: #cebb3f;
    color: var(--lista-dark-gray);
}

.select-category-button-wrapper .divider-vertical {
    height: 38px;
    margin: 0 15px;
    border-left: 1px solid #cebb3f;
    display: inline-block;
    vertical-align: top;
}

.top-tree-wrapper > div.top-tree-header {
    display: none;
}

.top-tree-wrapper.usedProcedureSteps > div.top-tree-header {
    display: flex;
    background-color: var(--lista-main-yellow);
    border-radius: 6px 6px 0 0;
    padding: 10px;
}

.top-tree-wrapper.usedProcedureSteps > div.top-tree-header p:first-child {
    margin-left: auto;
    margin-right: 120px;
}

.top-tree-wrapper.usedProcedureSteps > div.top-tree-header p {
    margin: 0;
    font-size: 14px;
}

.top-tree-wrapper.usedProcedureSteps > .tree-wrapper {
    border-radius: 0 0 5px 5px;
}

.tree-wrapper {
    background-color: #f7f8fb;
    border: 1px solid #e1e5ea;
    border-radius: 5px;
    padding: 1.5rem 0.5rem;
}

div.tree-wrapper.usedProcedureSteps {
    padding: 1.5rem 0.5rem 0.5rem 0.1rem;
    margin-bottom: 5rem;
}

div.tree-wrapper.usedProcedureSteps .tree {
    padding-bottom: 0.25rem;
}

div.tree-wrapper.usedProcedureSteps .tree .indent.dragger {
    transform: rotate(0deg);
    margin-right: 4px;
}

.tree .indent {
}

.tree .indent.dragger {
    font-size: 15px;
    margin: 0;
    flex-basis: 10%;
    text-align: center;
    transform: rotate(315deg);
    padding-left: 8.5px;
    padding-top: 13.5px;
}

.tree .indent.parent-open {
    color: white;
    border-top-left-radius: 5px;
    font-size: 10px;
    height: 41px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    vertical-align: top;
    min-width: 26px;
    max-width: 26px;
}

.tree .indent.parent-open:focus {
   outline: 0;
}

.tree .indent.parent-closed:focus {
   outline: 0;
}

.tree .indent.parent-closed {
    background-color: var(--main-orange);
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 10px;
    height: 41px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    vertical-align: top;
    min-width: 26px;
    max-width: 26px;
}

.tree .spacer {
    width: 26px;
    display: inline-block;
}

.tree .spacer-even {
    border-bottom-left-radius: 5px;
    height: 60px;
}

.tree .category .form-group {
    border: 1px solid #e1e5ea;
}

.tree .item.is-dragged-over,
.tree-root-item.selected-item-parent,
.tree .selected-item-parent {
    background-color: #f5f7fa;
}

.tree .delete-tree-item {
    color: #b33d31;
    cursor: pointer;
    /*display: inline-block;*/ /* @see TreeFormFields.css */
    padding: 5px;
    font-size: 18px;
}
.tree .edit-tree-item {
    color: #3b4249;
    cursor: pointer;
    /*display: inline-block;*/ /* @see TreeFormFields.css */
    margin-top: 3px;
    padding: 5px;
}
.tree .select-tree-item {
    color: #3b4249;
    cursor: pointer;
    /*display: inline-block;*/ /* @see TreeFormFields.css */
    padding: 5px;
    visibility: visible;
}
.tree .use-tree-item {
    color: #3b4249;
    cursor: pointer;
    padding: 0 5px;
    transform: translateY(6px);
}

.tree-wrapper.rootChildren > .tree .all-spacers, .tree-wrapper.rootChildren > .tree .indent.dragger {
    flex-basis: 0;
    padding-top: 15px;
    padding-left: 5px;
}

.tree-wrapper.rootChildren > .tree .is-child {
    width: 0!important;
}

.tree-wrapper.rootChildren > .tree .category {
    width: auto!important;
}

.tree-wrapper.rootChildren > .tree .category .form-group {
    width: 80%;
}

.tree-wrapper.rootChildren .tree .delete-tree-item {
    margin-left: auto;
}

.tree-wrapper.rootChildren  .tree .item {
    padding-left: 3px!important;
}

.tree-wrapper.rootChildren  .tree div.item:hover, .tree-wrapper.rootChildren .tree div.item input:hover, .tree-wrapper.rootChildren > .tree-wrapper > .tree:hover {
    cursor: pointer;
}

.update-category-tree > form > div:hover, .update-category-tree > form > div > div.form-group > input.form-control[type=text]:hover {
    cursor: pointer;
}

.tree-root-item:hover,
.tree-wrapper.rootChildren .tree .item:hover {
    background-color: rgba(250, 235, 143, .65);
}

.update-category-tree input.form-control[readonly]:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.update-category-tree > form {
    margin-top: 3.2rem;
}

.update-category-tree > form > div > div.form-control {
    border: 0;
}

.update-category-tree > form > button.pull-right[type=submit] {
    display: none;
}

.update-category-tree .fa-arrows-alt::before, .update-category-tree .fa-caret-down, .update-category-tree .fa-caret-right {
    color: var(--main-orange);
}

.side-drawer form .top-tree-wrapper button#add-category {
    width: 90%!important;
}

.rootChildren .tree .item .form-group {
    border: 0;
}

.side-drawer .update-category-tree {
    border-right: 0;
}

.update-category-tree {
    padding: 5px;
    height: 100%;
}
.update-category-tree .tree-wrapper,
.update-category-tree .tree .category .form-group,
.update-category-tree .tree .spacer-odd,
.update-category-tree .tree .spacer-even,
.update-category-tree .tree .indent.parent-open,
.update-category-tree .tree .indent.parent-closed {
    background-color: transparent;
    border-color: transparent;
}
.update-category-tree .tree-wrapper {
    padding-top: 0;
}
.update-category-tree .tree .spacer-even {
    height: 20px;
    margin-top: 0;
    margin-bottom: 0;
}
.update-category-tree .tree .indent.parent-open,
.update-category-tree .tree .indent.parent-closed {
    font-size: 16px;
    margin-left: 15px;
}
.update-category-tree .form-control {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.update-category-tree .form-control:disabled,
.update-category-tree .form-control[readonly] {
    background-color: transparent;
}
.update-category-tree .form-group {
    margin-bottom: 0;
}

.update-category-tree .is-dragged-over {
    background-color: var(--lista-main-yellow)!important;
}

/* slimmer category structure - start */
.update-category-tree .tree-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.update-category-tree .tree .indent.parent-open,
.update-category-tree .tree .indent.parent-closed {
    margin-left: 5px !important;
}
.update-category-tree .tree .spacer-odd,
.update-category-tree .tree .spacer-even,
.update-category-tree .tree .indent.parent-open,
.update-category-tree .tree .indent.parent-closed {
    width: 9px !important;
}
.update-category-tree .tree .indent.is-child {
    margin-left: 9px !important;
}
/* slimmer category structure - end */
.category .selected-item {
    display: flex;
}

/*.rootChildren > .tree > div > div[data-is-parent=true] .selected-item::before {*/
    /*content: 'Current Folder-';*/
    /*padding-top: 7px;*/
    /*font-size: 14px;*/
    /*min-width: 100px;*/
/*}*/

/*.rootChildren > .tree > div > div[data-is-parent=false] .selected-item::before {*/
    /*content: 'Current Category-';*/
    /*padding-top: 7px;*/
    /*font-size: 14px;*/
    /*min-width: 120px;*/
/*}*/

.selected-item-parent .selected-item input {
    padding-left: 2px;
}

.side-drawer .form-group {
    display: flex;
    flex-direction: column;
}

/*-- Folder Style Updates --*/

.tree-root-item {
    margin-top: .375em !important;
}

.tree-wrapper.rootChildren .tree .item.selected-item-parent,
.tree-root-item.selected-item-parent {
    background-color: #d2e9ff;
    border-radius: 4px;
}

.tree-root-item .form-control {
    padding-top: .625em;
}

.tree-root-item.selected-item-parent .form-control {
    font-weight: bold;
}

.update-category-tree .fa-caret-down,
.update-category-tree .fa-arrows-alt::before {
    color: #6ab5ff;
}

.update-category-tree .selected-item-parent .fa-caret-down,
.update-category-tree .selected-item-parent .fa-arrows-alt::before {
    color: #106ccc;
}

.update-category-tree .selected-item-parent .form-control,
.update-category-tree .selected-item-parent .selected-item {
    font-weight: bold;
}

/*.rootChildren > .tree > div > div[data-is-parent=true] .selected-item::before {*/
    /*content: 'Current Folder: ';*/
    /*min-width: 110px;*/
/*}*/

/*.rootChildren > .tree > div > div[data-is-parent=false] .selected-item::before {*/
    /*content: 'Current Category: ';*/
    /*min-width: 130px;*/
/*}*/

.tree-root-item.selected-item-parent .selected-item input {
    padding-left: .5rem;
}

.update-category-tree .tree [data-is-parent='true'].selected-item-parent .tree-item-field {
    padding-left: 9px;
}

.update-category-tree .tree .selected-item-parent .indent.is-child {
    margin-left: 18px !important;
}

.page-procedure-list .update-category-tree form::before,
.page-used-procedure-list .update-category-tree form::before {
    position: absolute;
    top: 15px;
    padding-left: 9px;
    font-weight: bold;
}

.page-used-procedure-list .update-category-tree form::before {
    content: "Browse by Folder";
}

.page-procedure-list .update-category-tree form::before {
    content: "Manage Folders";
}

.page-used-procedure-list .update-category-tree .fa-arrows-alt {
    display: none;
}

.page-used-procedure-list .tree-wrapper.rootChildren .tree .item {
    padding-left: 21px !important;
}

.page-used-procedure-list .update-category-tree .edit-tree-item {
    display: none;
}

.page-used-procedure-list .update-category-tree .item .tree-item-field {
    width: 100%;
}

/*---------------------------------START- Flex Box CSS for tree -START-----------------------------*/
.tree-wrapper .tree > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.tree-wrapper .tree > div > div.item {
    display: flex;
}

.tree-wrapper .tree > div > div.item  .procedure-step .form-group {
    width: 100%;
}

.tree-wrapper .tree .spacer-even {
    margin-top: -12px;
    margin-right:5px
}

.tree-wrapper .tree .all-spacers {
    display: flex;
    flex-basis: 30%;
}
/*======================= CREATE PROCEDURE ====================*/
.tree-wrapper.procedureSteps .tree > div > div.item span.indent.is-child-dragger {
    /*margin-left: 25px;*/
    margin-right: 1px;
}

.tree-wrapper.procedureSteps .tree .spacer-even {
    margin-right:0
}

.tree-wrapper.procedureSteps .tree > div > div.item span.indent.is-child {
    padding: 0 13px;
    width:0;
}

.tree-wrapper.procedureSteps .tree > div > div.item  span.tree-item-field {
    min-width: auto;
    max-width:100%;
    width: 100%;
    flex-basis: 1000000%; /*to have arrow-button fields meet spacers*/
}

.tree-wrapper.procedureSteps .tree > div > div.item  span.indent.spacer.spacer-odd {
 /* rem works best here, might be bc this procedure has alot more going on than use procedures */
    width: 1.57rem;
    min-width: 1.52rem;
    max-width: 1.45rem;
}

@media (max-width : 500px) {
    .tree-wrapper.procedureSteps .tree > div > div.item span.indent.spacer.spacer-odd {
        width: 1.56rem;
        min-width: 1.53rem;
        max-width: 1.53rem;
    }
}

.update-procedure .tree .procedure-step .extends-tree-item {
    position: absolute;
    width: 0;
}

.update-procedure .tree .procedure-step .extends-tree-item.isOpen .procedure-description-wrapper,
.update-procedure .tree .procedure-step .extends-tree-item.isOpen .procedure-attachment-wrapper{
    z-index: 20;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment-wrapper,
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description-wrapper {
    position: absolute;
    top: 25px;
    left: -5px;
    width: 500px;
    z-index: 10;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment-wrapper {
    left: 15px;
    width: 750px;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment-toggle,
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description-toggle {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    line-height: 21px;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 20px;
    vertical-align: top;
}
/*
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment-wrapper:not(.with-files) .procedure-attachment-toggle,
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description-wrapper:not(.with-description) .procedure-description-toggle {
    color: #777777;
}
*/
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment-container,
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description-container {
    background-color: #ffffff;
    border: 1px solid #e1e5ea;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment,
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description {
    padding: 20px;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-attachment .uppy-DashboardContent-back {
    display: none;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description .form-group {
    border: 0;
}
.update-procedure .tree .procedure-step .extends-tree-item .procedure-description .form-group .form-control {
    border: 0;
    height: auto;
    padding-left: 0;
    padding-right: 0;
}

.update-procedure .tree .procedure-step .extends-tree-item-wrapper {
    /*position: relative;*/
    width: 40px;
}
.update-procedure .tree .procedure-step .extends-tree-item.right-side {
    position: absolute;
    width: 0;
}
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-attachment-wrapper,
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-description-wrapper {
    top: 10px;
    left: auto;
    right: -20px;
}
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-attachment-wrapper {
    right: -40px;
}
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-attachment-toggle,
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-description-toggle {
    color: var(--main-orange);
    right: 0;
}
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-attachment-toggle span,
.update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-description-toggle span {
    font-size: 18px;
}
.update-procedure .tree .procedure-step .extends-tree-item.right-side .step-file-x,
.update-procedure .tree .procedure-step .extends-tree-item.right-side .step-description-x {
    right: 20px !important;
    top: 2px !important;
}
@media (max-width: 768px) {
    .update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-description-wrapper {
        /*width: 500px;*/
        width: auto;
        min-width: 250px;
        max-width: 500px;
    }
    .update-procedure .tree .procedure-step .extends-tree-item.right-side .procedure-attachment-wrapper {
        /*width: 750px;*/
        width: auto;
        min-width: 250px;
        max-width: 750px;
    }
}

.update-used-procedure .used-procedure-submit,
.update-procedure .procedure-submit {
    background-color: var(--main-orange);
    border-color: var(--main-orange);
    color: white;
    opacity: 1;
}
.update-used-procedure .used-procedure-submit .last-saved-wrapper,
.update-procedure .procedure-submit .last-saved-wrapper {
    display: block;
    margin-top: -5px;
    margin-bottom: -5px;
}
.update-used-procedure .used-procedure-submit .last-saved-wrapper .last-saved-text,
.update-procedure .procedure-submit .last-saved-wrapper .last-saved-text {
    display: block;
    font-size: 12px;
}
.update-used-procedure .used-procedure-submit .last-saved-wrapper .last-saved-time,
.update-procedure .procedure-submit .last-saved-wrapper .last-saved-time {
    display: block;
    font-size: 10px;
}

.step-description-x {
    position: absolute;
    right: 8px;
    top: 5px;
}

.step-description-x:focus, .step-file-x:focus {
    outline: none;
}

.step-file-x {
    position: absolute;
    right: 6px;
    top: 4px;
}

/*======================= USE PROCEDURE ====================*/
.tree-wrapper.procedureSteps .tree .indent.parent-open, .tree-wrapper.procedureSteps .tree .indent.parent-closed,
.tree-wrapper.usedProcedureSteps .tree .indent.parent-open,.tree-wrapper.usedProcedureSteps .tree .indent.parent-closed  {
    margin-top: .5rem;
    transform: translateX(3px);
}

.tree-wrapper .tree > div > div.item  .procedure-step {
    margin-top: .5rem;
}

.tree-wrapper.usedProcedureSteps .tree > div > div.item  .procedure-step .form-group {
    width: 100%;
    margin-bottom: 0;
    border-radius: 5px;
}

.tree-wrapper .tree > div > div.item  .procedure-step .form-group {
    margin-bottom:0;
    border-radius: 5px;
}

.tree-wrapper.usedProcedureSteps .tree .indent.dragger {
    padding-right:0;
    padding-left:9px!important;
}

.tree-wrapper.usedProcedureSteps .tree .spacer-even {
    margin-top: -12px;
    margin-right: 0;
}

.tree-wrapper.usedProcedureSteps .tree .spacer-odd {
    min-width: 16px;
    max-width: 16px;
}


.tree-wrapper.usedProcedureSteps .tree .is-child {
    margin-left: 26px;
}

.tree-wrapper.usedProcedureSteps .tree .tree-item-field {
    flex-basis: 1000000%;/*to have arrow-button and fields meet spacers*/
}

.tree-wrapper.usedProcedureSteps .tree .tree-item-field .used-procedure-description {
    font-size: 0.8rem;
    padding: 1rem;
}

.usedprocedureFilter-completed_status-wrapper {
    display: none!important;
}

/*-----------------------------------------END- Flex Box CSS for tree -END--------------------------------------*/

#use-procedure-checkmark-tooltip {
    background-color: transparent;
    border: 0;
}

button#use-procedure-checkmark-tooltip:focus {
    outline: none;
}

.visible-tooltip #checkmark-inner-tooltip,
button#use-procedure-checkmark-tooltip:focus #checkmark-inner-tooltip {
    opacity: 1;
    position: absolute;
    min-width: 350px;
    z-index: 99;
    transform: translateX(-310px);
    margin: 0;
    font-size: 12px;
    background-color: #EFEFEF;
    padding: 5px;
}

#checkmark-inner-tooltip{
    position: absolute;
    opacity: 0;
}

#add-category,
#add-procedure-step {
    background-color: #ffffff;
    color: #3b4249;
    padding: 0.5rem;
    text-align: center;
}

#add-category {
    margin-bottom: 1rem;
    position: absolute;
    top: 12px;
    left: 14px;
    width: 96.8% !important;
    background-color: var(--dark-yellow);
    border-color: #cebb3f;
    border-radius: 5px;
    color: #3b4249;
}

.update-category-tree button#add-category {
    border-style: solid;
    border-width: 1px;
    top: 8px;
    right: 8px;
    left: auto;
    width: auto !important;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

@media (max-width: 1200px) {/* #add-category btn is absolute, needs help w responsivness*/
    #add-category {
        width: 95% !important;
    }
}

@media (max-width: 767px) {
    #add-category {
        width: 98% !important;
    }
}

@media (max-width: 414px) {
    #add-category {
        width: 96% !important;
    }

    .side-drawer form .top-tree-wrapper button#add-category {
        width: 89%!important;
    }
}

/*.page-used-procedure-list .update-category-tree > form {*/
    /*margin-top: -15px;*/
/*}*/

.page-used-procedure-list .update-category-tree #add-category {
    display: none;
}

#add-procedure-step {
    margin-top: 1rem;
    border-radius: 5px;
    z-index: 1;
}
/* hidden, but still clickable for other operations */
.hidden-submit-button {
    border: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    width: 0 !important;
}

/* https://stackoverflow.com/questions/4148499/how-to-style-a-checkbox-using-css */
label.large-check input {
    display: none;
}
label.large-check span {
    background-color: white;
    border: 2px solid #bbb;
    display: inline-block;
    font-size: 20px;
    line-height: 23px;
    height: 27px;
    width: 27px;
    position: relative;
    text-align: center;
    border-radius: 5px;
}
label.large-check .check {
    color: #ffffff;
}

label.large-check span.fa-check {
    border: 2px solid #777;
}

label.large-check .fa-check {
    background-color: var(--lime-green);
}
/* https://unicode-table.com/en/#miscellaneous-symbols */
/*
label.large-check [type=checkbox]:checked + span:before {
    content: "\2714";
    position: absolute;
    top: -5px;
    left: 0;
}
*/

.update-used-procedure .form-control:disabled,
.update-used-procedure .form-control[readonly] {
    background-color: #ffffff;
}

/*
.update-used-procedure .usedprocedure-dueAtDate-wrapper,
.update-used-procedure .usedprocedure-tagNames-wrapper {
    position: relative;
    height: 40px;
}
.update-used-procedure .usedprocedure-tagNames {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
}
*/

.usedprocedure-tagNames__value-container .usedprocedure-tagNames__multi-value, .usedprocedure-tagNames__value-container .usedprocedure-tagNames__multi-value > .usedprocedure-tagNames__multi-value__label {
    background-color: var(--tag-color);
    color: #ffffff;
}

input#procedure_name , input#usedprocedure_procedureName {
    background-color: transparent;
}

.update-used-procedure .usedprocedure-dueAtDate-wrapper label,
.update-used-procedure .usedprocedure-tagNames-wrapper label {
    width: 100%;
}

.update-used-procedure .usedprocedure-tagNames__menu {
    z-index: 3;
}

.update-used-procedure .completed-stats {
    position: absolute;
    right: 50px;
    top: -5px;
    width: 300px;
}

@media (min-width: 768px) {
    .update-used-procedure .tree-field-container {
        padding-right: 270px;
    }
}

.update-used-procedure .completed-stats .stat-completed-by-name {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 200px;
    float:left;
}

.update-used-procedure .completed-stats .stat-completed-by-name input  {
    text-align: right;
    padding-right: 20px;
}

.update-used-procedure .completed-stats .stat-completed-at-date {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100px;
    float: left;
}

.update-used-procedure .completed-stats .form-group {
    border: 0;
}
.update-used-procedure .completed-stats input {
    background-color: transparent !important;
    border: 0;
    color: #b3b3b3;
    font-size: 0.9rem;
    padding-left: 0;
    padding-right: 0.25rem;
}
.update-used-procedure .completed-stats input,
.update-used-procedure .completed-stats input:focus {
    outline: none;
    box-shadow: none !important;
}

.update-used-procedure .used-procedure-step-not-applicable-wrapper {
    position: absolute;
    right: 30px;
    top: 0;
    width: 50px;
}

.update-used-procedure .used-procedure-step-not-applicable-wrapper input {
    display: none;
}
.update-used-procedure .used-procedure-step-not-applicable-wrapper .badge-light {
    color: #666;
    background-color: #dcdbdc;
}

.update-used-procedure .used-procedure-step-not-applicable-wrapper .badge-light:hover {
    cursor: pointer;
    color: #333;
    background-color: var(--main-orange);
}

.update-used-procedure .form-control.tree-field-container.is-not-applicable {
    color: #999;
    font-weight: normal;
    /*text-decoration: line-through;*/
}

.update-used-procedure .used-procedure-info-wrapper {
    position: relative;
}
.update-used-procedure .used-procedure-info-actions {
    position: absolute;
    top: -13px;
}
.update-used-procedure .used-procedure-info-actions span {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    line-height: 21px;
    padding: 0;
    text-align: center;
    width: 20px;
    vertical-align: top;
}
.update-used-procedure .used-procedure-info-note .form-group {
    border: 0;
    padding-top: 15px;
}
.update-used-procedure .used-procedure-info-note .form-group .form-control {
    border: 0;
    height: auto;
    padding-left: 0;
    padding-right: 0;
}
.update-used-procedure .used-procedure-info-description .used-procedure-description-wrapper {
    padding-top: 15px;
}
.update-used-procedure .used-procedure-info-files .used-procedure-step-files-wrapper {
    padding-top: 15px;
}

.update-used-procedure .used-procedure-info-wrapper.right-side .used-procedure-info-actions {
    top: -30px;
    right: 85px;
    font-size: 18px;
}
.update-used-procedure .used-procedure-info-wrapper.right-side .used-procedure-info-actions span {
    color: var(--tag-color);
}
.update-used-procedure .completed-stats {
    right: 110px;
}

/* ----------------- PAGE LIST ---------------------*/
.page-list #add-category {
    left: 6px;
    /*width: 96% !important;*/
}

.page-list .nav {
    margin-bottom: 40px;
}

.page-list .nav .nav-link {
    color: #aaacb0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.page-list .nav .nav-link h1 {
    margin-bottom: 0;
    padding-bottom: 0;
}

.page-list.page-procedure-list .nav .nav-link.active h1,
.page-list.page-used-procedure-list .nav .nav-link.active h1 {
    border-bottom: 4px solid black;
}

.page-list.page-procedure-list .nav .nav-link:hover span,
.page-list.page-used-procedure-list .nav .nav-link:hover span {
    border-bottom: 4px solid #aaacb0;
}

.page-list .nav .nav-link.active {
    color: #3b4249;
}

.page-list > .row {
    background-color: #f5f7fa;
    border: 1px solid #dce1e6;
    border-radius: 5px;
}

.page-list .procedureTemplateListContainer {
    border: 2px solid var(--border-gray);
}

.page-list .table-responsive {
    display: table;
    overflow-x: visible;
}
.page-list .dropdown-toggle {
    background-color: transparent!important;
    border-color: transparent;
    /*color: inherit;*/
    color: var(--dodger-blue);
    padding: 0 0.25rem;
}

.page-list .dropdown-menu {
    transform: translate3d(-96px, 27px, 0px)!important;
}
.page-list .btn-primary:not(:disabled):not(.disabled):active,
.page-list .btn-primary:not(:disabled):not(.disabled).active,
.page-list .show > .btn-primary.dropdown-toggle {
    background-color: #dce1e6;
    border-color: transparent;
    /*color: inherit;*/
    color: #3054d1;
}
.page-list .dropdown-toggle:after {
    content: initial;
}
.page-list .table .fa {
    color: #000;
}

.page-list .table a , .page-list .table .fa-ellipsis-v {
    color: var(--dodger-blue);
    font-weight: normal;
}

.page-list .table .primary-action {
    white-space: nowrap;
}
.page-list .table .primary-action span:not(.fa),
.page-list .table .clone-action span:not(.fa),
.page-list .table .edit-action span:not(.fa),
.page-list .table .view-action span:not(.fa) {
    color: var(--dodger-blue);
}
.page-list .table .delete-action {
    color: #b33d31;
}
#lista-dashboard .table .progress-bar-completed .progress-bar,
.page-list .table .progress-bar-completed .progress-bar {
    /*
    color: #3a77b3;
    color: #61cbcc;
    */
    background: rgb(58,119,179);
    background: -moz-linear-gradient(90deg, rgba(58,119,179,1) 0%, rgba(97,203,204,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(58,119,179,1) 0%, rgba(97,203,204,1) 100%);
    background: linear-gradient(90deg, rgba(58,119,179,1) 0%, rgba(97,203,204,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3a77b3",endColorstr="#61cbcc",GradientType=1);
}
#lista-dashboard .table .progress-bar-mid .progress-bar,
.page-list .table .progress-bar-mid .progress-bar {
    /*
    color: #50b30e;
    color: #5dcc00;
    */
    background: rgb(80,179,14);
    background: -moz-linear-gradient(90deg, rgba(80,179,14,1) 0%, rgba(93,204,0,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(80,179,14,1) 0%, rgba(93,204,0,1) 100%);
    background: linear-gradient(90deg, rgba(80,179,14,1) 0%, rgba(93,204,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#50b30e",endColorstr="#5dcc00",GradientType=1);
}
#lista-dashboard .table .progress-bar-low .progress-bar,
.page-list .table .progress-bar-low .progress-bar {
    /*
    color: #b39400;
    color: #cb6701;
    */
    background: rgb(179,148,0);
    background: -moz-linear-gradient(90deg, rgba(179,148,0,1) 0%, rgba(203,103,1,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(179,148,0,1) 0%, rgba(203,103,1,1) 100%);
    background: linear-gradient(90deg, rgba(179,148,0,1) 0%, rgba(203,103,1,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b39400",endColorstr="#cb6701",GradientType=1);
}

.page-list .filter-form .form-group {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.page-list .filter-form > form > button.btn-primary.pull-right[type=submit] {
    display: none;
}

.page-list .filter-form .form-group:nth-of-type(1) {
    width: 50%;
}
.page-list.page-used-procedure-list .filter-form .form-group:nth-of-type(1) {
    width: 25%;
}
.page-list.page-used-procedure-list .filter-form .form-group.usedprocedureFilter-completed_status-wrapper {
    width: 150px;
}
.page-list.page-used-procedure-list .filter-form .form-group.usedprocedureFilter-tags-wrapper {
    min-width: 100px;
}
.page-list.page-procedure-list .filter-form .form-group.procedureFilter-author-wrapper,
.page-list.page-tag-list .filter-form .form-group.tagFilter-author-wrapper,
.page-list.page-used-procedure-list .filter-form .form-group.usedprocedureFilter-author-wrapper {
    min-width: 150px;
}

.page-list.page-procedure-list {
    margin-bottom: 1rem;
}

/* ----------------- USER EDIT && TAG EDIT ---------------------*/
#edit-user-container, .edit-tag-container {
    margin-top: 2rem;
}

#edit-user-container h1 , .edit-tag-container h1 {
    padding-left: 20px;
    margin-bottom: 20px;
}

#edit-user-container form , .edit-tag-container form {
    background-color: #f7f8fb;
    padding: 20px;
}

#edit-user-container div.mt-3 , .edit-tag-container div.mt-3 {
    padding-left: 15px;
    margin-top: 20px!important;
}

#edit-user-container div.mt-3 , .edit-tag-container div.mt-3 > button {
    margin-left: 0.5rem;
}

.edit-tag-wrapper .page-actions-wrapper , .edit-user-wrapper .page-actions-wrapper  , .invite-user-wrapper .page-actions-wrapper  {
    height: 90px;
}

.edit-tag-wrapper .page-actions-wrapper .page-actions, .edit-user-wrapper .page-actions-wrapper .page-actions , .invite-user-wrapper .page-actions-wrapper .page-actions {
    float: none;
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
}

.edit-tag-wrapper .page-actions-wrapper .page-actions.new-tag-actions, .invite-user-wrapper .page-actions-wrapper .page-actions.invite-user-action {
    float: right;
}

/* Edge browser specific */
@supports (-ms-ime-align: auto) {
    .update-used-procedure .tree-wrapper .tree {
        width: 100.5%; /* slightly larger to prevent side scrollbar */
    }
}

/*Firefox specific*/
@-moz-document url-prefix() {
    .tree .indent.parent-open {
        min-width: 27px;
    }

    .tree .spacer {
        width: 27px;
    }

    .tree-wrapper.usedProcedureSteps .tree .spacer-odd {
        width: 16px!important;
        min-width: 16.5px;
        max-width: 14px;
    }

    @media (max-width: 500px) {
        .tree-wrapper.usedProcedureSteps .tree .spacer-odd {
            min-width: 16px;
        }
    }
}

@media( max-width: 768px) {
    #add-procedure-step {
        width: 25%!important;
    }
}

@media( max-width: 576px) {
   .form-actions-wrapper , .page-actions-wrapper {
       width: 104%;
       margin-left: -2%;
       margin-right: 0;
       padding-left: 0;
       padding-right: 0;
   }

    #add-procedure-step {
        width: 45%!important;
    }

    .mt-3 > form button.btn.btn-primary.pull-right {
        position: absolute;
        right: 7px;
    }

    .mt-3 form div.pull-right div.select-category-button {
        text-align: right;
    }

    .mt-3 div.form-actions-wrapper > .form-actions , .page-actions-wrapper .page-actions {
        padding: 0 10px;
    }
}

/*--- Design Tweaks July 2020 ---*/

#basic-navbar-nav .nav-link {
    font-size: 1.25em;
    line-height: 1.1;
}

.page-list.page-used-procedure-list .col-md-9,
.page-list.page-procedure-list .col-md-9 {
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.update-used-procedure form > .form-group.used-procedure-title {
    width: 50%;
    float: left;
    clear: both;
}

.update-used-procedure form > .form-group.usedprocedure-dueAtDate-wrapper,
.update-used-procedure form > .form-group.usedprocedure-tagNames-wrapper {
    width: 25%;
    float: left;
}

.update-used-procedure form > .form-group.usedprocedure-dueAtDate-wrapper {
    padding-left: 0.5rem;
}

.update-used-procedure form .usedProcedureSteps {
    clear: both;
}

.update-used-procedure form input[name="procedureName"]:focus {
    box-shadow: none;
    border: none;
    outline: none;
}

.update-used-procedure .editing-using-procedure-label {
    padding: 0;
    margin: 0;
    color: var(--dodger-blue);
    font-weight: bold;
}

.update-used-procedure div#usedprocedure_procedureName,
.update-used-procedure input[name="procedureName"] {
    padding: 0;
    font-weight: bold;
    height: auto;
}

.update-used-procedure .tree .spacer-even {
    background-color: transparent;
}

.update-used-procedure .tree-wrapper.usedProcedureSteps {
    background-color: white;
}

.update-used-procedure .tree .category .form-group, .tree .procedure-step .form-group {
    border-top: none;
    border-right: none;
    border-left: none;
}

.update-used-procedure .tree .category .form-group, .tree .procedure-step .form-group .form-control {
    height: 40px;
}

.update-used-procedure .tree-wrapper.usedProcedureSteps .tree > div > div.item .procedure-step .form-group {
    border-radius: 0;
}

.update-used-procedure .form-control.tree-field-container {
    font-weight: bold;
}

.update-used-procedure form > .form-group:first-of-type::before {
    display: inline-block;
    content: '';
    width: 4em;
    height: 4em;
    position: absolute;
    left: 0;
    top: .25em;
    background-image: url('../img/Lista-icon-ProcedureInUse.png');
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.update-used-procedure form > .form-group:first-of-type {
    padding-left: 5.5em;
    position: relative;
}

@media screen and (max-width: 500px) {
    .update-used-procedure form > .form-group:first-of-type,
    .update-used-procedure form > .form-group.usedprocedure-dueAtDate-wrapper,
    .update-used-procedure form > .form-group.usedprocedure-tagNames-wrapper {
        width: 100%;
    }
}

.update-used-procedure .used-procedure-info-note .form-group {
    margin-right: 5px;
}

.update-used-procedure .used-procedure-info-note .form-group {
    margin-bottom: 10px;
}

.update-used-procedure .used-procedure-info-note .form-group .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.update-used-procedure .used-procedure-info-note .form-group .ql-container  {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.update-used-procedure .used-procedure-info-note .form-group + .btn.btn-primary {
    margin-bottom: 16px;
}

.update-used-procedure .used-procedure-info-description .used-procedure-description-wrapper .used-procedure-description {
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: -2px;
    margin-right: 15px;
    border-radius: 4px;
}

.update-used-procedure .used-procedure-info-note button.btn-primary {
    display: block;
    clear: both;
    margin-right: 5px;
}

.medium-zoom-image--opened {
    z-index: 3;
}

.used-procedure-step-files {
    text-align: right;
    padding-right: 5px;
}

.update-used-procedure form #new-submit-button,
.update-procedure form #new-submit-button {
    background-color: var(--dark-yellow);
    border-color: #cebb3f;
    color: #3b4249;
    margin-left: 6px;
}

@media screen and (min-width: 992px) {
    .update-used-procedure .used-procedure-info-note .form-group,
    .update-used-procedure .used-procedure-info-description .used-procedure-description-wrapper .used-procedure-description {
        width: 50%;
        display: block;
        float: right;
    }
    .update-used-procedure .used-procedure-info-description .used-procedure-description-wrapper .used-procedure-description {
        width: calc(50% + 8px);
    }
    .update-used-procedure .used-procedure-info-note button.btn-primary {
        display: block;
        clear: both;
        margin-right: 5px;
    }
}

@media screen and (max-width: 991px) {
    .update-used-procedure .used-procedure-description.text-muted {
        padding-left: 0 !important;
    }
}

/* Micro-interactions and animations */

@keyframes lista-strikethrough {
    0%   { width : 0; }
    100% { width: calc(100% - 270px - .75em); }
}

.update-used-procedure .tree-field-container.is-not-applicable {
    position: relative;
}

/*.update-used-procedure .tree-field-container.is-not-applicable::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 8px;*/
/*    width: calc(100% - 270px - .75em);*/
/*    height: 2px;*/
/*    background: #999;*/
/*    animation-name: lista-strikethrough;*/
/*    animation-duration: 1s;*/
/*    animation-timing-function: ease-in;*/
/*    animation-iteration-count: 1;*/
/*    animation-fill-mode: forwards;*/
/*}*/

.update-used-procedure input[value="true"]+.check::after {
    content: '';
    border-radius: 16px;
    background-color: var(--lista-marketing-green);
    position: absolute;
    box-sizing: border-box;
    top: 12px;
    right: 13px;
    transform: translate(-50%, -50%) scale(0);
    width: 1.8px;
    height: 1.8px;
}

.update-used-procedure .used-procedure-step-not-applicable-wrapper input[value="true"]+.badge::after {
    content: '';
    border-radius: 16px;
    background-color: var(--danger);
    position: absolute;
    box-sizing: border-box;
    top: 12px;
    right: 34px;
    transform: translate(-50%, -50%) scale(0);
    width: 1.8px;
    height: 1.8px;
}

@keyframes ripple {
    0% { transform: scale(0, 0); opacity: 1; }
    40% { transform: scale(15, 15); opacity: 1; }
    100% { transform: scale(30, 30); opacity: 0; }
}

.update-used-procedure .used-procedure-step-not-applicable-wrapper input[value="true"]+.badge::after,
.update-used-procedure input[value="true"]+.check::after {
    animation: ripple .6s ease-in;
}

@keyframes checkfade {
    0% { display:none ; opacity: 0; }
    1% { display: block ; opacity: 0; }
    100% { display: block ; opacity: 1; }
}

.update-used-procedure input[value="true"]+.check::before {
    animation: checkfade .8s ease-out;
}

@keyframes boxfade {
    0% { background-color: transparent;}
    100% { background-color: var(--lime-green); }
}

.update-used-procedure input[value="true"]+.check {
    animation: boxfade .8s ease-out;
}

.tree-item-field {
    flex-grow: 2;
}

.tree-wrapper.rootChildren > .tree .category .form-group {
    width: 95%;
}

/* Sticky Headers */

.scrolling:not(.blue-sticky) {
    position: fixed;
    top: 0;
    height: 0;
    overflow: hidden;
    z-index: 11;
    width: 100%;
    transition-property: height;
    transition-duration: 1s;
    padding: 0;
}

nav.navbar.scrolling:not(.blue-sticky) > div.custom-container > .navbar-brand > *,
nav.navbar.scrolling:not(.blue-sticky) > div.custom-container > .navbar-brand,
nav.navbar.scrolling:not(.blue-sticky) > div.custom-container > .navbar-collapse {
    opacity: 0;
    height: 0;
    transition-property: height, opacity;
    transition-duration: 1s;
}

.scrolling.blue-sticky {
    height: 86px;
    position: fixed;
    top: 0;
    z-index: 12;
    width: 100%;
    transition-property: height;
    transition-duration: 1s;
}

nav.navbar.scrolling:not(.blue-sticky) > div.custom-container > .navbar-brand > *,
nav.navbar.scrolling.blue-sticky > div.custom-container > .navbar-brand,
nav.navbar.scrolling.blue-sticky > div.custom-container > .navbar-collapse {
    opacity: 1;
    transition-property: opacity, height;
    transition-duration: 1s;
}

.scrolling + .custom-container {
    margin-top: 86px;
}

.form-actions-wrapper.yellow-sticky {
    position: fixed;
    z-index: 11;
    top: 0;
    margin-top: 0;
    transition-property: top;
    transition-duration: 1s;
}

.form-actions-wrapper.yellow-sticky + form {
    padding-top: 86px;
}

.form-actions-wrapper.yellow-sticky + form > button[type="submit"] {
    position: fixed;
    z-index: 12;
    top: 16px;
    transition-property: top;
    transition-duration: 1s;
}

.form-actions-wrapper.yellow-sticky .used-procedure-actions-wrapper,
.yellow-sticky + form .procedure-update-actions-wrapper {
    position: fixed;
    top: 16px;
    z-index: 12;
    transition-property: top;
    transition-duration: 1s;
}

#new-submit-button {
    background-color: var(--dark-yellow);
    border-color: #cebb3f;
    color: #3b4249;
    margin-left: 6px;
}

.form-actions-wrapper.yellow-sticky + form > button[type="submit"].used-procedure-submit,
.form-actions-wrapper.yellow-sticky + form > button[type="submit"].procedure-submit {
    right: 113px;
}

.blue-sticky + .custom-container .form-actions-wrapper.yellow-sticky {
    top: 86px;
    transition-property: top;
    transition-duration: 1s;
}

.blue-sticky + .custom-container .form-actions-wrapper.yellow-sticky + form > button[type="submit"] {
    top: 102px;
}

/* Button Adjustments Jan 2022 */

.update-used-procedure form #new-submit-button,
.update-procedure form #new-submit-button,
.btn-cta,
button.btn-cta,
a.btn-cta {
    background-color: var(--cta-red);
    border-color: var(--darker-cta-red);
    color: white;
}

.update-used-procedure form #new-submit-button:hover,
.update-procedure form #new-submit-button:hover,
.btn-cta:hover,
button.btn-cta:hover,
a.btn-cta:hover {
    background-color: var(--cta-red);
    border-color: var(--darker-cta-red);
    color: white;
    filter: contrast(80%);
}

.btn.cancel-subscription:hover,
.btn.submit-card:hover,
.btn-info:hover {
    background-color: var(--dark-yellow);
    border-color: #cebb3f;
    color: #3b4249;
    filter: contrast(80%);
}

.update-used-procedure .used-procedure-submit,
.update-procedure .procedure-submit {
    color: #3b4249;
    background-color: var(--dark-yellow);
    border-color: var(--dark-yellow);
}

/*------------------------------------------*/
/*------ App-Wide Design Refresh 2022 ------*/
/*------------------------------------------*/

/* Global Design Updates */

.PrivateLayout > .navbar {
    box-shadow: 0 3px 9px rgba(0,0,0,.3);
}

.PrivateLayout #basic-navbar-nav > .navbar-nav {
    width: 100%;
}

@media all and (min-width: 992px) {
    #basic-navbar-nav .navbar-nav > .nav-link,
    #basic-navbar-nav #help-collapsible-menu,
    #basic-navbar-nav #collapsible-nav-dropdown {
        box-shadow: 5px 5px 10px rgba(0,0,0,.15);
        font-size: 1.125rem;
        font-weight: 600;
        padding: 1.0625rem 1.5rem .9375rem;
        border: 2px solid var(--lighter-dodger-blue);
        border-radius: .75rem;
    }
    #basic-navbar-nav .navbar-nav > .nav-link.active {
        background-color: var(--lighter-dodger-blue);
        color: white;
    }
    #basic-navbar-nav .navbar-nav > .nav-link:hover,
    #basic-navbar-nav #help-collapsible-menu:hover,
    #basic-navbar-nav #collapsible-nav-dropdown:hover {
        color: white;
        box-shadow: none;
        background-color: var(--darker-dodger-blue);
    }
    #basic-navbar-nav .navbar-nav > .nav-link:not(.mr-auto),
    #basic-navbar-nav .navbar-nav > .nav-item {
        margin-right: 24px;
    }
}

.organization-link.active-organization {
    font-weight: 600;
}

.organization-link.active-organization a::after {
    content: " (Current)";
}

.organization-link {
    display: flex;
    flex-wrap: nowrap;
}
.organization-link a {
    flex-grow: 2;
    padding-right: 1rem;
}
.organization-link .ml-1 {
    padding: 0;
}

#help-collapsible-menu + .dropdown-menu,
.user-navigation .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.organization-link .ml-1 {
    color: var(--dodger-blue);
}

/* Dashboard and Onboarding */

.onboarding-step-1::after,
.onboarding-step-2::after {
    content: "";
    display: block;
    padding-bottom: 14%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2rem;
}

.onboarding-step-1::after {
    background-image: url('../img/Lista-Step1.png');
}

.onboarding-step-2::after {
    background-image: url('../img/Lista-Step2.png');
}

/* Procedure List Page */

.mt-3.page-used-procedure-list,
.mt-3.create-name-procedure,
.mt-3.page-procedure-list {
    margin-top: 2.25rem !important;
}

.update-category-tree {
    border: none;
    background-color: var(--light-fill-gray);
}

.update-category-tree .form-control {
    font-size: .9375rem;
    color: var(--lista-dark-gray);
}

.tree-wrapper.rootChildren .tree .item.selected-item-parent,
.tree-root-item.selected-item-parent {
    background-color: var(--lista-lavender);
}

.tree-root-item:hover,
.tree-wrapper.rootChildren .tree div.item:hover {
    background-color: var(--lista-lighter-lavender);
    border-radius: 4px;
}

.update-category-tree > form {
    border-top: 2px solid #ccc;
}

.update-used-procedure .form-actions-wrapper .btn-danger,
.update-procedure .form-actions-wrapper .btn-danger,
.update-category-tree button #add-category,
.btn.cancel-subscription,
.btn.submit-card,
.btn-info {
    background-color: var(--lista-marketing-yellow);
    border: none;
    color: var(--lista-dark-gray);
}

.update-used-procedure .form-actions-wrapper .btn-danger,
.update-procedure .form-actions-wrapper .btn-danger,
.btn-info,
.update-category-tree button#add-category,
.btn-cta, button.btn-cta, a.btn-cta {
    border: none;
    padding: .5rem .625rem;
    font-weight: 600;
}

.update-category-tree .selected-item-parent .form-control,
.update-category-tree .selected-item-parent .selected-item {
    font-weight: 600;
}

.page-procedure-list .filter-form .form-control,
.page-procedure-list .procedureFilter-author__control {
    border-color: #ccc;
    border-width: 2px;
    border-radius: 6px;
}

.btn-cta,
.page-procedure-list .filter-form .form-control {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
    height: calc(1.5em + .875rem + 2px);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--light-fill-gray);
}

.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.page-procedure-list .table th,
.page-procedure-list .table td {
    border-top: none;
}

.page-list .table th a {
    font-weight: 700;
}

.page-list .table a .fa {
    color: var(--dodger-blue);
}
.page-list .filter-form {
    display: flex;
}

.page-list .filter-form form {
    flex-grow: 1;
}

.update-used-procedure .form-actions-wrapper button .fa,
.update-used-procedure .used-procedure-actions-wrapper .fa,
.page-list.page-procedure-list .table .edit-action .fa,
.page-list table .primary-action .fa,
.filter-form .btn-cta .fa {
    margin-right: .25rem;
}

.page-list.page-procedure-list .table .edit-action {
    white-space: nowrap;
    text-decoration: underline;
}

.page-list .primary-action {
    margin-right: 1rem;
}

.page-list .table .fa.fa-bars {
    color: var(--dodger-blue);
    margin-right: 1rem;
}

.page-procedure-list thead th:first-of-type {
    padding-left: calc(.75rem + 14px + 1rem); /* original padding + icon font-size + icon margin-right */
}

.page-procedure-list tbody th {
    cursor: grab;
}

/* Procedure Create page */

.create-name-procedure form::after {
    display: block;
    height: 50vh;
    margin-top: 10vh;
    width: 100%;
    background: url('../img/Lista-StepOne.jpg') no-repeat center center;
    background-size: contain;
    content: '';
}

/* Procedure Update page */

.update-procedure .editing-using-procedure-label {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--lista-marketing-green2);
}

.update-used-procedure .form-actions-wrapper,
.update-procedure .form-actions-wrapper {
    background-color: var(--border-gray);
    border: none;
}

.tree .indent.parent-open {
    background-color: var(--tag-color);
}

.tree .spacer-even {
    border-right: 2px solid var(--tag-color);
    background-color: white;
}

.tree-wrapper.procedureSteps {
    border: 3px solid var(--border-gray);
    border-radius: 6px;
    background-color: white;
}

.procedure-step input.form-control {
    background-color: var(--border-gray);
}

.tree .procedure-step .form-group {
    border: none;
}

.update-used-procedure .form-actions-wrapper,
.update-procedure .form-actions-wrapper {
    float: right;
    text-align: right;
}

.update-used-procedure .form-actions-wrapper .btn-danger,
.update-procedure .form-actions-wrapper .btn-danger {
    margin-right: 0;
}

.update-used-procedure .used-procedure-submit,
.update-procedure .procedure-submit {
    background-color: transparent;
    border: none;
}

.update-procedure .select-category-button-wrapper,
.update-procedure .select-category-button-wrapper > div {
    display: inline-block;
}

#add-procedure-step,
.update-used-procedure form #new-submit-button,
.update-procedure form #new-submit-button {
    border: none;
    font-weight: 600;
    padding: .5rem .625rem;
    margin: 0;
}

.update-used-procedure form .divider-vertical,
.update-procedure form .divider-vertical {
    border-color: #DCE1E6;
    display: inline-block;
    vertical-align: middle;
    height: 40px;
}

.update-used-procedure .used-procedure-submit .last-saved-wrapper .last-saved-text,
.update-procedure .procedure-submit .last-saved-wrapper .last-saved-text {
    font-size: .8125rem;
    font-weight: 500;
}

.update-used-procedure .used-procedure-submit .last-saved-wrapper .last-saved-time,
.update-procedure .procedure-submit .last-saved-wrapper .last-saved-time {
    font-size: .6875rem;
    font-weight: 500;
}

.folder-label-wrapper .form-group {
    margin-bottom: 0;
}

.update-used-procedure .used-procedure-submit,
.procedure-update-actions-wrapper .procedure-submit {
    padding-right: 0;
}

.keyboard-shortcut-legend-ul li h5 {
    font-weight: 600;
}

.keyboard-shortcut-legend-ul li {
    font-weight: 400;
    color: var(--lista-dark-gray);
}

.procedure-step .form-control[type="text"] {
    font-weight: 500;
    color: var(--lista-dark-gray);
}

.procedure-step .delete-tree-item .fa-trash {
    color: var(--cta-red);
}

.procedure-step .extends-tree-item-wrapper .fa {
    color: var(--tag-color);
}

#add-procedure-step {
    background-color: var(--cta-red);
    color: white;
}

#add-procedure-step:hover,
#add-procedure-step:active {
    filter: contrast(0.8);
}

.btn-info:active,
.update-used-procedure .form-actions .btn-danger:active,
.update-procedure .form-actions .btn-danger:active {
    filter: contrast(0.8);
}

.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(253,192,5,.5);
}

.btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:active,
.update-procedure .select-category-button .btn-info:active,
.update-procedure .select-category-button .btn-info:focus {
    background-color: var(--lista-marketing-yellow);
    color: var(--lista-dark-gray);
}

.update-procedure form #new-submit-button:active,
.update-procedure .select-category-button .btn-info:active {
    filter: contrast(0.8);
}

#add-procedure-step {
    margin-left: 3rem;
    margin-top: 1.875rem;
    display: block;
}

.tree-wrapper.procedureSteps {
    padding-bottom: 60px;
}


.yellow-sticky .form-actions .btn-danger {
    position: fixed;
    right: 38px;
}

.yellow-sticky ~ form .used-procedure-actions-wrapper,
.yellow-sticky ~ form .procedure-update-actions-wrapper {
    position: fixed;
    top: 16px;
    z-index: 15;
    transition-property: top;
    transition-duration: 1s;
}

.blue-sticky ~ .custom-container .used-procedure-actions-wrapper,
.blue-sticky ~ .custom-container .procedure-update-actions-wrapper {
    top: 102px;
    transition-property: top;
    transition-duration: 1s;
}

#tooltip-delete .tooltip-inner {
    background-color: var(--cta-red);
    font-weight: 500;
}

#tooltip-delete .arrow::before {
    border-top-color: var(--cta-red);
}

#tooltip-note .tooltip-inner,
#tooltip-description .tooltip-inner,
#tooltip-attachments .tooltip-inner {
    background-color: var(--tag-color);
    font-weight: 500;
}

#tooltip-note .arrow::before,
#tooltip-description .arrow::before,
#tooltip-attachments .arrow::before {
    border-top-color: var(--tag-color);
}

/* Use Procedure page */

.update-used-procedure .editing-using-procedure-label {
    color: var(--lista-marketing-green2);
}

.used-procedure-step .large-check .fa-check {
    background-color: var(--lista-marketing-green);
    border: none;
    line-height: 27px; /* match height of element to vertically center */
}

.top-tree-wrapper.usedProcedureSteps > div.top-tree-header {
    border: none;
    font-weight: 700;
    color: var(--lista-dark-gray);
}

.usedprocedure-tagNames__control {
    border-width: 2px;
}

.update-used-procedure div#usedprocedure_procedureName {
    font-size: 40px;
    line-height: 48px;
}

.update-used-procedure .editing-using-procedure-label {
    font-size: 1rem;
    text-transform: none;
    letter-spacing: normal;
}

.update-used-procedure .usedprocedure-dueAtDate-wrapper label,
.update-used-procedure .usedprocedure-tagNames-wrapper label {
    font-size: 1rem;
    font-weight: 500;
}

.usedprocedure-tagNames-wrapper .usedprocedure-tagNames__control,
.usedprocedure-dueAtDate-wrapper input {
    border: 2px solid #ccc;
}

.usedprocedure-dueAtDate-wrapper input {
    height: calc(1.5em + 0.75rem + 4px);
}

.update-used-procedure .form-control.tree-field-container {
    font-weight: 600;
    color: var(--lista-dark-gray);
}

.update-used-procedure .form-control.tree-field-container.is-completed-step:not(.is-not-applicable) {
    font-weight: 600;
    color: var(--lista-marketing-green2);
}

.used-procedure-step-not-applicable .badge-danger {
    background-color: var(--cta-red);
}

.used-procedure-info-files .btn-primary {
    background-color: var(--lista-marketing-yellow);
    border: none;
    padding: .5rem .625rem;
    font-weight: 600;
    color: var(--lista-dark-gray);
}

.used-procedure-info-files .btn:only-child {
    margin-right: 0 !important;
}

.used-procedure-info-files .btn-primary:hover,
.used-procedure-info-files .btn-primary:active,
.used-procedure-info-files .btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--lista-marketing-yellow);
    color: var(--lista-dark-gray);
    filter: contrast(0.8);
}

.used-procedure-info-files .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgb(253 192 5 / 50%);
}

.update-used-procedure .yellow-sticky .form-actions .btn-yellow {
    position: fixed;
    right: 116px;
    margin-right: 0;
}

.used-procedure-actions-wrapper .export-pdf-button {
    margin-left: .5rem;
}
