.purchasable-procedure-container {
    background-color: #f7f8fb;
    border: 3px solid var(--border-gray);
    border-radius: 6px;
    margin-left: 25px;
    margin-bottom: 25px;
    /*padding: 15px;*/
    font-weight: 400;
    white-space: nowrap;
}

.purchasable-procedure-hidden {
    display: none;
}

.flex-basis-20 {
    flex-basis: 20%;
}

.purchasable-procedure-container > h5, .purchasable-procedure-container form {
    /*padding-inline: 15px;*/
}

.purchasable-procedure-container input {
    border: 2px solid var(--border-med-gray);
    border-radius: 6px;
}

.purchasable-procedure-container > h5 {
    font-weight: 700;
}

.purchasable-procedure-container > hr  {
    border: 1px solid var(--border-med-gray);
    margin-inline: 5px;
}

.purchasable-procedure-container .left-inner-addon {
    position: relative;
}
.purchasable-procedure-container .left-inner-addon input {
    padding-left: 20px;
}
.purchasable-procedure-container .left-inner-addon span {
    position: absolute;
    padding: 7px 10px 5px;

    pointer-events: none;
}

.purchasable-procedure-container .image-upload img {
    cursor: pointer;
}

.purchasable-procedure-container .fa-trash {
    display: none;
}

.purchasable-procedure-container .image-container {
    width: min-content;
    position: relative;
}

.purchasable-procedure-container .image-container:hover .fa-trash {
    display: initial;
    font-size: 22px;
    color: var(--cta-red);
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 3px;
}

